import {Box, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Button, IconButton, Grid, Typography,} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
import { PlayCircle } from "@mui/icons-material";
import LongFormDialog from "./Dialog";
import "../styles/gamedetail.css";
import TextInput from "./TextInput";
import img from '../assets/svg/image-picker-icon.png'

export default function GameComponent({ gameData, mode, onSubmit, onFieldChange, onFileChange, templates, gameId, groups,plans }) {
  const [formState, setFormState] = useState({
    template: false,
    Description: '',
    GroupID: '',
    GameDetail: '',
    BannerFile: null,
    StartDateTime: '',
    EndDateTime: '',
    MinPlayers: '',
    MaxPlayers: '',
    GameType: '',
    VideoURL: '',
    RegistrationFee: '',
    TotalPrizeMoney: '',
    LeaderboardBanner: '',
    PayoutType: '',
    PlanId: '',
    Latitude: '',
    Longitude: '',
    IsSaveAsTemplate: false,
    InGameTrading: false,
  });
console.log(templates,'TEMP');

  const [errors, setErrors] = useState('');
  const [open, setOpen] = useState(false);
  const bannerInputRef = useRef(null);
  const leaderboardBannerRef = useRef(null);
  const videoRef = useRef(null);
  const location = useLocation();
  const isViewMode = mode === true;
console.log(isViewMode,'ISView Mode');

  useEffect(() => {
    if (gameData && gameId) {
      console.log(gameData,'da')
      const convertDateTime = (dateTimeStr) => {
        const date = new Date(dateTimeStr);
        
        const localDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, 16);
        
        console.log(dateTimeStr, 'UTC to Local', localDateTime);
        return localDateTime;  // Returns the adjusted local time from UTC in 'YYYY-MM-DDTHH:MM' format
      };
  
      setFormState({
        template: gameData.IsSaveAsTemplate,
        Description: gameData.Description,
        GroupID: gameData.GroupID.trim(),
        GameDetail: gameData.GameDetail,
        BannerFile: gameData.BannerFile,
        StartDateTime: gameData.StartDateTime?convertDateTime(gameData.StartDateTime):'', // Convert here
        EndDateTime:gameData.EndDateTime?convertDateTime(gameData.EndDateTime):'', // Convert here
        MinPlayers: gameData.MinPlayers,
        MaxPlayers: gameData.MaxPlayers,
        InGameTrading: gameData.InGameTrading === "true",
        GameType: gameData.GameType,
        VideoURL: gameData.VideoURL,
        RegistrationFee: gameData.RegistrationFee,
        TotalPrizeMoney: gameData.TotalPrizeMoney,
        LeaderboardBanner: gameData.LeaderboardBanner,
        PayoutType: gameData.PayoutType,
        PlanId: gameData.PlanId,
        Latitude: gameData.Latitude,
        Longitude: gameData.Longitude,
        IsSaveAsTemplate: gameData.IsSaveAsTemplate,
      });
    }
  }, [gameData]);
  const isPlanIdDisabled = () => {
    const registrationFee = parseFloat(formState.RegistrationFee) || 0;
    const totalPrizeMoney = parseFloat(formState.TotalPrizeMoney) || 0;
  
    return (registrationFee !== 0 && totalPrizeMoney !== 0) || totalPrizeMoney !== 0;
  };

  const handleChange = (event) => {
    const { type, checked, value, name } = event.target;
    let newValue;
    console.log(name,'NAME',value,'VALUE');

    if (type === "checkbox") {
      newValue = checked;
    }
    else if (name === "RegistrationFee" || name === "TotalPrizeMoney" || name === "Latitude" || name === "Longitude") {
      newValue = parseFloat(value) || '';
    } 
    else if (name === "GameType" && value === "Free") {
      setFormState((prevState) => ({
        ...prevState,
        RegistrationFee: 0.00,
      }));
      newValue = value
    } 
    else {
      newValue = value;
    }
     console.log(name,newValue);
     
    setFormState((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    onFieldChange(event, newValue);
  };

  const handleTemplateChange = (event) => {
    const selectedTemplateId = event.target.value;
    const selectedTemplate = templates.find((template) => template.ID === selectedTemplateId);
    console.log(selectedTemplate,'SELECTED TEMPATE');
    // const convertDateTime = (dateTimeStr) => {
    //   const date = new Date(dateTimeStr);
    //   return date.toISOString().slice(0, 16); // Convert to 'YYYY-MM-DDTHH:MM' format
    // };

    if (selectedTemplate) {
      const today = new Date();
      const todayDate = today.toISOString().split('T')[0]; // Extracts YYYY-MM-DD from ISO string
      
      // Combine today's date with StartTime from the template
      const startDateTime = `${todayDate}T${selectedTemplate.StartTime}`;
      const endDateTime = `${todayDate}T${selectedTemplate.EndTime}`;
      setFormState((prevState) => ({
        ...prevState,
        // template: selectedTemplateId,
        Description: selectedTemplate.Description,
        StartDateTime: startDateTime,
        BannerFile:selectedTemplate.BannerFile,
        GameDetail:selectedTemplate.GameDetail,
        EndDateTime: endDateTime,
        MinPlayers: selectedTemplate.MinPlayers ,
        MaxPlayers: selectedTemplate.MaxPlayers,
        InGameTrading: selectedTemplate.InGameTrading =='NO'?false:true,
        GameType: selectedTemplate.GameType ||'',
        VideoURL: selectedTemplate.VideoURL ||'' ,
        RegistrationFee: selectedTemplate.RegistrationFee ,
        TotalPrizeMoney: selectedTemplate.TotalPrizeMoney ,
        LeaderboardBanner: selectedTemplate.LeaderboardBanner ,
        PayoutType: selectedTemplate.PayoutType ,
        PlanId: selectedTemplate.PlanId ,
        Latitude: selectedTemplate.Latitude ,
        Longitude: selectedTemplate.Longitude ,
      }));
    }
    
  };

  const handleImageUpload = (event, key) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormState((prevState) => ({
        ...prevState,
        [key]: reader.result, // Update with the key
      }));
    };
    reader.readAsDataURL(event.target.files[0]);
    onFileChange(event);
  };
  

  const validateFields = () => {
    const newErrors = {};
    console.log(formState,'Sdendjk');
    
    if (formState.Description.length==0) newErrors.Description = "Game name is required";
    if (formState.StartDateTime.length==0) newErrors.startDateTime = "Start Date Time is required";
    if (formState.EndDateTime.length==0) newErrors.endDateTime = "End Date Time is required";
    if (formState.MaxPlayers.length==0) newErrors.maxPlayers = "Max Players is required";
    if (formState.MinPlayers.length==0) newErrors.minPlayers = "Min Players is required";
    if (formState.RegistrationFee.length==0) newErrors.registrationFee = "Registration Fee is required";
    // if (formState.TotalPrizeMoney.length==0) newErrors.totalPrizeMoney = "Total Prize Money is required";
    return newErrors;
  };

  const handleSubmit = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    console.log(formState,'formStateformStateformState');
    
    onSubmit(formState); // Call the submit function if validation passes
  };

  const triggerFileInput = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handlePlayVideo = () => {
    setOpen(true);
  };
const handleClose = () => {
  if (videoRef.current) {
    videoRef.current.onloadedmetadata=()=>{
      videoRef.current.pause(); // Pause the video
      videoRef.current.currentTime = 0; // Reset the video to the beginning
    }
   
  }
  setOpen(false); // Close the dialog

}
  const Comp = () => {
    useEffect(() => {
      if (videoRef.current && formState.VideoURL) {
        videoRef.current.src = formState.VideoURL;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play();
        };
      }
    }, [formState.VideoURL]);

    return (
      <Grid item xs={12}>
        {formState.VideoURL && (
          <video ref={videoRef} autoPlay loop muted width={'100%'}>
            Your browser should support this video file
          </video>
        )}
      </Grid>
    );
  };

  
  

  return (
    <div>
    <Typography fontFamily={"Lato"} fontSize={28} fontWeight={700}>
      {gameId ? 'Game Detail' : 'Create Game'}
    </Typography>

    <Box className="game-detail-container">
      <Grid container spacing={2}>
        {!gameId && (
          <Grid item xs={12} md={3}>
            <FormControl sx={{ minWidth: 120,color:'white' }} variant="outlined" fullWidth className="form-field form-template">
              <InputLabel sx={{color:'white'}}>Choose a template</InputLabel>
              <Select name="Template" value={formState.template || ""} onChange={handleTemplateChange} disabled={isViewMode} displayEmpty>
                <MenuItem disabled value="">
                  Choose a template
                </MenuItem>
                {templates.map((template) => (
                  <MenuItem color="white" key={template.ID} value={template.ID}>
                    {template.Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} md={gameId ? 6 : 5}>
          <TextInput
            label="Game Name"
            name="Description"
            value={formState.Description}
            variant="outlined"
            fullWidth
            disabled={isViewMode}
            
            onChange={handleChange}
            className="form-field"
          />
          {errors.gameName && (
            <Typography marginLeft={1} fontSize={12} color="error">
              {errors.gameName}
            </Typography>
          )}
        </Grid>
          <Grid item xs={12} md={gameId?6:4}>
          {groups?.length > 0 && (
  <FormControl variant="outlined" fullWidth className="form-field">
    <InputLabel>Group</InputLabel>
    <Select
      name="GroupID"
      value={formState.GroupID}
      label="Group"
      onChange={handleChange}
      disabled={isViewMode}
    >
      {groups?.map((item) => {
        return (
          <MenuItem value={item.GroupID}>{item.GroupID}-{item.GroupName}</MenuItem>
        );
      })}
    </Select>
  </FormControl>
)}
            {errors.groupId && (
              <Typography marginLeft={1} fontSize={12} color="error">
                {errors.groupId}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              label="Game Detail"
              name="GameDetail"
              multiline
              rows={4}
              value={formState.GameDetail}
              variant="outlined"
              fullWidth
              disabled={isViewMode}
              onChange={handleChange}
              className="form-field"
            />
            {errors.gameDetail && (
              <Typography marginLeft={1} fontSize={12} color="error">
                {errors.gameDetail}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <div className="image-section">
              <div className="image-upload">
                <div onClick={() => triggerFileInput(bannerInputRef)}>
                  {formState.BannerFile ? (
                    <img
                      src={formState.BannerFile}
                      alt="Banner"
                      className="uploaded-img"
                    />
                  ) : isViewMode&& !formState.BannerFile ? (<div className="upload-placeholder">
                           {/* <p color="gray">No Image</p>  */}
                  </div>):
                  (
                    <div className="upload-placeholder">
                    <IconButton color="primary" component="span">
                     <img src={img}/>
                   </IconButton>
                   <Typography color={'gray'}>
                     Drag & Drop or <span>Browse</span>
                   </Typography>
                  
                 
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    name="BannerFile"
                    ref={bannerInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleImageUpload(e,'BannerFile')}
                    disabled={isViewMode}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item container spacing={2} xs={12} md={6}>
            <Grid item xs={12} md={6}>
              <TextInput
                defaultValue="Start Date Time"
                label="Start Date Time"
                name="StartDateTime"
                type="datetime-local"
                value={formState.StartDateTime}
                variant="outlined"
                fullWidth
                disabled={isViewMode}
                onChange={handleChange}
                className="form-field"
                InputLabelProps={{ shrink: true }}
              />
              {errors.startDateTime && (
                <Typography marginLeft={1} fontSize={12} color="error">
                  {errors.startDateTime}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                label="End Date Time"
                name="EndDateTime"
                type="datetime-local"
                value={formState.EndDateTime}
                variant="outlined"
                fullWidth
                disabled={isViewMode}
                onChange={handleChange}
                className="form-field"
                InputLabelProps={{ shrink: true }}
              />
              {errors.endDateTime && (
                <Typography marginLeft={1} fontSize={12} color="error">
                  {errors.endDateTime}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                label="Min Players"
                name="MinPlayers"
                value={formState.MinPlayers}
                variant="outlined"
                fullWidth
                disabled={isViewMode}
                onChange={handleChange}
                type='number'
                className="form-field"
              />
              {errors.minPlayers && (
                <Typography marginLeft={1} fontSize={12} color="error">
                  {errors.minPlayers}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                label="Max Players"
                name="MaxPlayers"
                value={formState.MaxPlayers}
                variant="outlined"
                fullWidth
                disabled={isViewMode}
                onChange={handleChange}
                  type='number'
                className="form-field"
              />
              {errors.maxPlayers && (
                <Typography marginLeft={1} fontSize={12} color="error">
                  {errors.maxPlayers}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="image-section">
              <div className="image-upload">
                <div onClick={() => triggerFileInput(leaderboardBannerRef)}>
                  {formState.LeaderboardBanner ? (
                    <img
                      src={formState.LeaderboardBanner}
                      alt="leaderboardbanner"
                      className="uploaded-img"
                    />
                  ) :
                    (
                      <div className="upload-placeholder">
                      <IconButton color="primary" component="span">
                       <img src={img}/>
                     </IconButton>
                     <Typography color={'gray'}>
                       Drag & Drop or <span>Browse</span>
                     </Typography>
                    
                   
                      </div>
                    )}
                  <input
                    type="file"
                    accept="image/*"
                    name="LeaderboardBanner"
                    ref={leaderboardBannerRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleImageUpload(e,'LeaderboardBanner')}
                    disabled={isViewMode}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  name="InGameTrading"
                  checked={formState.InGameTrading}
                  onChange={handleChange}
                  disabled={isViewMode}
                />
              }
              label="Allow In Game Trading"
              className="form-field"
              style={{ color: "gray" }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl
              variant="outlined"
              fullWidth
              disabled={isViewMode}
              className="form-field"
            >
              <InputLabel>Game Type</InputLabel>
              <Select
                name="GameType"
                value={formState.GameType}
                label="Game Type"
                onChange={handleChange}
              >
                <MenuItem value={'Free'}>{'Free'}</MenuItem>
                <MenuItem value={'Paid'}>{'Paid'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Promo Video URL"
              name="VideoURL"
              value={formState.VideoURL}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: !isViewMode && (
                  <IconButton disabled={!formState.VideoURL} onClick={handlePlayVideo}>
                    <PlayCircle />
                  </IconButton>
                ),
              }}
              disabled={isViewMode}
              onChange={handleChange}
              className="form-field"
            />
          </Grid>

          <Grid item xs={12} md={3}>
          <TextInput
            label="Registration Fee"
            name="RegistrationFee"
            value={formState.RegistrationFee} // Will be set to 0 automatically when "Free" is selected
            variant="outlined"
            fullWidth
            disabled={isViewMode || formState.GameType === "Free"} // Disable the field when game type is "Free"
            onChange={handleChange}
            className="form-field"
          />
            {errors.registrationFee && (
              <Typography marginLeft={1} fontSize={12} color="error">
                {errors.registrationFee}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              label="Total Prize Money ($)"
              name="TotalPrizeMoney"
              value={formState.TotalPrizeMoney}
              variant="outlined"
              fullWidth
              disabled={isViewMode}
              onChange={handleChange}
              className="form-field"
            />
            {errors.totalPrizeMoney && (
              <Typography marginLeft={1} fontSize={12} color="error">
                {errors.totalPrizeMoney}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              label="Payout Ratio"
              name="PayoutType"
              value={formState.PayoutType}
              variant="outlined"
              fullWidth
              disabled={isViewMode}
              onChange={handleChange}
              className="form-field"
            />
            {errors.payoutRatio && (
              <Typography marginLeft={1} fontSize={12} color="error">
                {errors.payoutRatio}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
          {plans?.length > 0 && (
            <FormControl variant="outlined" fullWidth className="form-field">
              <InputLabel>Plan</InputLabel>
              <Select
                name="PlanId"
                value={formState.PlanId}
                label="Plan"
                onChange={handleChange}
                disabled={isViewMode || isPlanIdDisabled()}
              >
                {plans?.map((item) => (
                  <MenuItem key={item.planId} value={item.planId}>
                    {item.planId} - {item.name}{/* Assuming `PlanName` is the field you want to display */}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {errors.planId && (
            <Typography marginLeft={1} fontSize={12} color="error">
              {errors.planId}
            </Typography>
          )}
            {errors.planId && (
              <Typography marginLeft={1} fontSize={12} color="error">
                {errors.planId}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} md={3}>
            <TextInput
              label="Latitude"
              name="Latitude"
              value={formState.Latitude}
              variant="outlined"
              fullWidth
              disabled={isViewMode}
              onChange={handleChange}
              className="form-field"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              label="Longitude"
              name="Longitude"
              value={formState.Longitude}
              variant="outlined"
              fullWidth
              disabled={isViewMode}
              onChange={handleChange}
              className="form-field"
            />
          </Grid>
        </Grid>

        {!isViewMode && (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
          {!gameId &&  <FormControlLabel
              control={
                <Checkbox
                  name="IsSaveAsTemplate"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label="Save as Template"
              style={{ marginRight: "16px", color: "gray" }} // Add some spacing between the checkbox and the button
            />}
            <Button
              variant="contained"
              className="submit-button"
              onClick={handleSubmit}
              sx={{
                background:
                  "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)",
                color: "#fff", // Ensure the text color is readable
                "&:hover": {
                  background:
                    "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                },
              }}
            >
          {gameId? 'Update':   'Submit'}
            </Button>
          </div>
        )}
      </Box>
      <LongFormDialog open={open} handleClose={handleClose}>
        <Comp />
      </LongFormDialog>
    </div>
  );
}
