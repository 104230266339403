import React, { useState } from 'react';
import { Grid, IconButton, Button, Typography } from '@mui/material';
import deleteIcon from '../assets/svg/delete-icon.svg';
import TextInput from './TextInput';
import { createReward, updateReward } from '../services/job';
import '../styles/gamedetail.css'
import Spinner from './Spinner';
export default function RewardForm({ reward }) {
  const [rewardRanges, setRewardRanges] = useState(reward ? reward.rewardDetails : [{ startPosition: "", endPosition: "", description: "", amount: "",  redeemPasscode: "" }]);
  const [planId, setPlanId] = useState(reward?.planId || '');
  const [passcode, setPasscode] = useState(reward?.passcode || '');
  const [rewardName, setRewardName] = useState(reward?.name || '');
  const [rewardType, setRewardType] = useState(reward?.rewardType || '');
  const [expirationDate, setExpirationDate] = useState(reward?.rewardExpiryDate || '');
  const[loading,setLoading]=useState(false)
   console.log(reward,'Rewarddsssssssssss');

  const [errors, setErrors] = useState({
    planId: '',
    passcode: '',
    rewardName: '',
    rewardType: '',
    amount:'',
    expirationDate: '',
    rewardRanges: [{ startPosition: '', endPosition: '', description: '',  redeemPasscode : '' }], // Initialize properly
  });

  const isRangeComplete = (range) => {
    return (range.startPosition && range.endPosition && range.description  && range.redeemPasscode) || (range.startPosition && range.endPosition  && range.amount);
  };

  const validateFields = () => {
    const newErrors = {
      // planId: planId ? '' : 'Please enter Plan ID.',
      passcode: passcode ? '' : 'Please enter Passcode.',
      rewardName: rewardName ? '' : 'Please enter Reward Name.',
      rewardType: rewardType ? '' : 'Please enter Reward Type.',
      // expirationDate: expirationDate ? '' : 'Please enter Expiration Date.',
      rewardRanges: rewardRanges.map(range => ({
        startPosition: range.startPosition ? '' : 'Please enter Start Position.',
        endPosition: range.endPosition ? '' : 'Please enter End Position.',
        // description: range.description ? '' : 'Please enter Description.',
        // amount: range.amount ? '' : 'Please enter Amount.',
        // redeemPasscode: range.redeemPasscode ? '' : 'Please enter Passcode.',
      })),
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(value => value) && !newErrors.rewardRanges.some(rangeErrors => Object.values(rangeErrors).some(error => error));
  };

  const handleAddRewardRange = () => {
    const incompleteRanges = rewardRanges.filter(range => !isRangeComplete(range));
    if (incompleteRanges.length === 0) {
      setRewardRanges([...rewardRanges, { startPosition: "", endPosition: "", description: "", amount: "", redeemPasscode: "" }]);
    } else {
      alert("Please fill in all fields of the existing reward ranges before adding another.");
    }
  };

  const handleRemoveRewardRange = (index) => {
    const newRanges = [...rewardRanges];
    newRanges.splice(index, 1);
    setRewardRanges(newRanges);
  };

  const handleChange = (index, field, value) => {
    if (index >= 0 && index < rewardRanges.length) {
      const newRanges = [...rewardRanges];
      newRanges[index][field] = value;

      const newErrors = { ...errors };
      if (newErrors.rewardRanges[index]) {
        newErrors.rewardRanges[index][field] = ''; // Clear the error for this specific field
      }
      console.log(newRanges,'REANDLLEWKEKWE');
      
      
      setErrors(newErrors);
      setRewardRanges(newRanges);
    } else {
      console.error(`Index ${index} is out of bounds.`);
    }
  };

  const handleFieldChange = (field, value) => {
    console.log(value,'Valueeeee');
    
    switch (field) {
      case 'planId':
        setPlanId(value);
        setErrors({ ...errors, planId: '' });
        break;
      case 'passcode':
        setPasscode(value);
        setErrors({ ...errors, passcode: '' });
        break;
      case 'rewardName':
        setRewardName(value);
        setErrors({ ...errors, rewardName: '' });
        break;
      case 'rewardType':
        setRewardType(value);
        setErrors({ ...errors, rewardType: '' });
        break;
      case 'expirationDate':
        setExpirationDate(value);
        setErrors({ ...errors, expirationDate: '' });
        break;
      default:
        break;
    }
  };


  const handleCreateReward = async () => {
   
    const rewardData = {
      PlanId: parseInt(planId, 10),
      Name: rewardName,
      RewardType: rewardType,
      RewardExpiryDate: expirationDate,
      RewardDetails: rewardRanges.map((range) => ({
        Id: 0, 
        StartPosition: parseInt(range.startPosition, 10),
        EndPosition: parseInt(range.endPosition, 10),
        RedeemPasscode: range.redeemPasscode,
        Description: range.description,
        PrizeMoney: parseFloat(range.amount) || 0, // Assuming prizeMoney is derived from the amount
      })),
    };
      console.log(rewardData, 'Reward Data to be sent');
    if(rewardRanges.length == 0 ){
      alert('Please add reward range')
      console.log('kskjdsjkd');
      
    }
    else  if(validateFields()){
      if(reward){
        setLoading(true)
        updateReward(rewardData).then(res=>{
          console.log(res,'RESPONSE');  
          setLoading(false)
          // alert('Reward updated successfully!')
        }).catch(er=>{
          console.log(er,'Error in updating reward');  
          alert('Error in updating reward!')

        })
      }
      else{
          try {
            setLoading(true)
            console.log('jhfjhnwjfdhqwfhdikqjl');
            
            const response = await createReward(rewardData);
            setLoading(false)
            alert(response.message)
            console.log(response, 'Response from the API');
          } 
          catch (error) {
            setLoading(false)

            console.log('Error creating reward:', error);
            alert('Error in creating reward')
          }
      }
    }
    else{
      try {
        // setLoading(true)
        console.log('jhfjhnwjfdhqwfhdikqjl');
        
        const response = await createReward(rewardData);
        setLoading(false)
        alert(response.message)
        console.log(response, 'Response from the API');
      } 
      catch (error) {
        setLoading(false)

        console.log('Error creating reward:', error);
        alert('Error in creating reward')
      }
       
    }
  };
if(loading){
  return(<Spinner/>)
}
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextInput
          label="Plan ID#"
          name="planId"
          disabled={reward?.planId}
          fullWidth
          value={planId}
          onChange={(e) => handleFieldChange('planId', e.target.value)}
          error={!!errors.planId}
          helperText={errors.planId}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Passcode"
          name="passcode"
          fullWidth
          value={passcode}
          onChange={(e) => handleFieldChange('passcode', e.target.value)}
          error={!!errors.passcode}
          helperText={errors.passcode}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Reward Name"
          name="rewardName"
          fullWidth
          value={rewardName}
          onChange={(e) => handleFieldChange('rewardName', e.target.value)}
          error={!!errors.rewardName}
          helperText={errors.rewardName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          label="Reward Type"
          name="rewardType"
          fullWidth
          value={rewardType}
          onChange={(e) => handleFieldChange('rewardType', e.target.value)}
          error={!!errors.rewardType}
          helperText={errors.rewardType}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography marginBottom={1} fontFamily={'Lato'} fontSize={16} fontWeight={500}>Reward Range</Typography>
        {rewardRanges.map((range, index) => (
          <Grid paddingBottom={2} paddingTop={2} container spacing={2} key={index}>
            <Grid item xs={2}>
              <TextInput
                label="Start Position"
                value={range.startPosition}
                onChange={(e) => handleChange(index, "startPosition", e.target.value)} // Fix the typo here
                error={!!errors.rewardRanges[index]?.startPosition}
                helperText={errors.rewardRanges[index]?.startPosition}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextInput
                label="End Position"
                value={range.endPosition}
                onChange={(e) => handleChange(index, "endPosition", e.target.value)}
                error={!!errors.rewardRanges[index]?.endPosition}
                helperText={errors.rewardRanges[index]?.endPosition}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                label="Description"
                value={range.description}
                onChange={(e) => handleChange(index, "description", e.target.value)}
                error={!!errors.rewardRanges[index]?.description}
                helperText={errors.rewardRanges[index]?.description}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextInput
                label="Amount ($)"
                value={range.prizeMoney}
                onChange={(e) => handleChange(index, "amount", e.target.value)}
                error={!!errors.rewardRanges[index]?.amount}
                helperText={errors.rewardRanges[index]?.amount}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextInput
                label="Passcode"
                value={range.redeemPasscode}
                onChange={(e) => handleChange(index, "redeemPasscode", e.target.value)}
                error={!!errors.rewardRanges[index]?.redeemPasscode}
                helperText={errors.rewardRanges[index]?.redeemPasscode}
                fullWidth
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton style={{ alignSelf: 'center' }} onClick={() => handleRemoveRewardRange(index)}>
                <img src={deleteIcon} alt="delete" />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        <Button onClick={handleAddRewardRange} variant="contained" style={{ color: 'gray', fontSize: 17, textTransform: 'unset', border: '1px dashed lightgrey', backgroundColor: 'transparent', width: '100%', boxShadow: 'none' }}>
          + Add
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          label="Expiration Date"
          name="expirationDate"
          fullWidth
          type="datetime-local"
          value={expirationDate}
          onChange={(e) => handleFieldChange('expirationDate', e.target.value)}
          error={!!errors.expirationDate}
          helperText={errors.expirationDate}
          variant="outlined"
          className="form-field"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12}>
  <Grid container justifyContent="flex-end">
    <Button
      variant="contained"
      className="submit-button"
      onClick={handleCreateReward}
      sx={{
        background: 'linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)',
        color: '#fff', // Ensure the text color is readable
        '&:hover': {
          background: 'linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)',
        },
      }}
    >
      {reward ? 'Update Reward' : 'Create Reward'}
    </Button>
  </Grid>
</Grid>

    </Grid>
  );
}
