import React, { useState } from 'react';
import '../../styles/Login.css';
import logo from '../../assets/svg/login-header.svg';
import { Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { authenticateUser } from '../../services/job';
import Spinner from '../../components/Spinner';
import TextInput from '../../components/TextInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ username: '', password: '' });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

 
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset errors
    setErrors({ username: '', password: '' });

    // Basic validation
    let hasErrors = false;
    if (!username) {
      setErrors((prevErrors) => ({ ...prevErrors, username: 'Email is required' }));
      hasErrors = true;
    } else if (!/\S+@\S+\.\S+/.test(username)) {
      setErrors((prevErrors) => ({ ...prevErrors, username: 'Email is invalid' }));
      hasErrors = true;
    }
    if (!password) {
      setErrors((prevErrors) => ({ ...prevErrors, password: 'Password is required' }));
      hasErrors = true;
    }

    if (hasErrors) {
      return; // Prevent form submission if there are errors
    }

    setLoading(true);
    let data = {
      username: username,
      password: password
    };

    authenticateUser(data)
      .then(res => {
        console.log(res, 'Res Data');
      })
      .catch(er => {
        console.log(er, 'Error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
     <div className="container">
      <div className="login-container">
      <Spinner />
      </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="login-container">
        <div className="image-div">
          <img src={logo} alt="DayTrader Logo" />
          <div className="login-box">
            <Typography
              style={{ fontSize: 28, paddingBottom: 2, fontWeight: 700 }}
            >
              Sign In
            </Typography>
            <Typography
              paddingBottom={2}
              fontWeight={400}
              fontSize={14}
              color={"rgba(255, 255, 255, 0.6)"}
            >
              Enter your email and password to <br /> access your account
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                label="Email Address"
                type="email"
                fullWidth
                value={username}
                onChange={(e) => { setErrors({ username: '', password: '' }); setUsername(e.target.value)}}
                error={Boolean(errors.username)}
                helperText={errors.username}
              
                InputLabelProps={{ style: { color: "rgba(255,255,255,0.6)" } }} // Adjust label color
                InputProps={{
                  style: {
                    color: "rgba(255,255,255,0.6)", // Adjust text color
                    backgroundColor:  "transparent" , // Transpareparent background
                    borderRadius: "8px",
                    marginBottom: errors.username.length == 0 ?'25px':"0px",
                 
                  },
                  notchedOutline: {
                    borderColor: "rgba(255,255,255,0.6)", // Border color
                  },
                }}
                FormHelperTextProps={{
                  sx: {
                    color: 'red', // Customize error message color
                    fontSize: 12, // Adjust font size
                    marginLeft: '0', // Adjust left margin if needed
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "rgba(255,255,255,0.6)", // Default border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#f7f4f4", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(255,255,255,0.6)", // Border color when focused
                    }, 
                    

                  },
                }}
              />
            <TextField
              variant="outlined"
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              onChange={(e) => { setErrors({ username: '', password: '' }); setPassword(e.target.value) }}
              error={Boolean(errors.password)}
              helperText={errors.password}
              InputLabelProps={{ style: { color: "rgba(255,255,255,0.6)" } }} // Adjust label color
              FormHelperTextProps={{
                sx: {
                  color: 'red', // Customize error message color
                  fontSize: 12, // Adjust font size
                  marginLeft: '0', // Adjust left margin if needed
                },
              }}
              InputProps={{
                notchedOutline: {
                  borderColor: "rgba(255,255,255,0.6)", // Border color
                },
                style: {
                  color: "rgba(255,255,255,0.6)", // Adjust text color
                  backgroundColor: "transparent", // Transparent background
                  borderRadius: "8px",
                  // Removed WebkitTextFillColor to avoid hiding entered text
                  marginBottom: errors.password.length === 0 ? '25px' : "0px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      sx={{ color: 'grey' }}
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(255,255,255,0.6)", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "#f7f4f4", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(255,255,255,0.6)", // Border color when focused
                  },
                },
              }}
                  />
              <Button
                variant="contained"
                className="login-button"
                onClick={handleSubmit}
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)",
                  color: "#fff", // Ensure the text color is readable
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                  },
                }}
              >
                Sign In
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
