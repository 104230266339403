import React from 'react';
import GameComponent from '../../components/GameComponent';

function GameDetails() {
  const gameData = {
    gameName: '10 AM Free Game',
    group: 'PFREE',
    gameDetail: 'Dunkin Donuts Giveaway...',
    bannerImage: '/path-to-banner-image.jpg',
    leaderboardBanner: '/path-to-leaderboard-banner.jpg',
    startDateTime: '11/11/2019 12:00',
    endDateTime: '11/11/2019 13:00',
    minPlayers: 5,
    maxPlayers: 100,
    allowTrading: true,
    gameType: 'Free',
    videoUrl: 'https://vimeo.com/932294708',
    registrationFee: 0.00,
    totalPrizeMoney: 100.00,
    payoutRatio: '60-30-10',
    planId: '20001',
    latitude: '41.023650',
    longitude: '-80.681110',
    status: 'Running',
  };

  const leaderboardData = [
    { rank: '#1', userName: 'ShivamArora98', score: '456.27' },
    { rank: '#2', userName: 'KeertiNayak123', score: '367.50' },
    { rank: '#3', userName: 'NishitKayala678', score: '202.43' },
    { rank: '#4', userName: 'Sandeep Sohan345', score: '-589.99' },
  ];

  const handlePlayVideoClick = () => {
    console.log('Play video clicked');
  };

  return (
      <GameComponent
        gameData={gameData} 
        leaderboardData={leaderboardData} 
        onPlayVideoClick={handlePlayVideoClick} 
      />
  );
}

export default GameDetails;
