import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography, CircularProgress, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/CustomTable';
import deleteIcon from '../../assets/svg/delete-icon.svg';
import edit from '../../assets/svg/edit-icon.svg';
import '../../styles/global.css';
import '../../styles/allgames.css';
import { deleteGameFromTable, getAllGames } from '../../services/job';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import renderParticipants from '../../components/ProgressBar'
import Spinner from '../../components/Spinner'
export default function AllGames() {
  const [filter, setFilter] = useState('Pending');
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(0); // For current page index
  const [pageSize, setPageSize] = useState(100); // Records per page

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true)
    let filters= (filter).toUpperCase()
    if(filters=='PENDING'){
      filters='OPEN'
    }
    try {
      const response = await getAllGames(filters, page, pageSize);
      const data = JSON.parse(response)
      console.log(JSON.parse(response),'RESSSSs');
      console.log('ress',data.Table);
      const tableData = data.Table;
      console.log("---------->>>><<<<<--------(Ressssss)", tableData);
      const mappedRows = tableData.map((game) => ({
        ...game,
        id: game.GameID,
      }));
      console.log(mappedRows,'MAPPPED ROWs');

      setRows(mappedRows);

      if (tableData.length > 0) {
        let dynamicColumns = [];

        if (filter === 'Pending') {
          dynamicColumns = [
            { field: 'GameID', headerName: '#ID', flex: 1 },
            { field: 'Description', headerName: 'Name', flex: 2 },
            { field: 'StartDateTime', headerName: 'Start Date', flex: 2 },
            { field: 'EndDateTime', headerName: 'End Date', flex: 2 },
            { field: 'NumRegistered', headerName: 'Participants',headerAlign:'center', flex: 1.5, align: 'right', renderCell: renderParticipants, }, // Align right
            { field: 'TotalPrizeMoney', headerName: 'Total Prize', flex: 1.5,headerAlign:'right', align: 'right' }, // Align right
           { field: 'action',
            headerName: 'Action',
            headerAlign:'center',
             align: 'right',
            width: 150,
            renderCell: renderActions,}
          ];
        } else if (filter === 'Running' || filter === 'Completed') {
          dynamicColumns = [
            { field: 'GameID', headerName: '#ID', flex: 1 },
            { field: 'Description', headerName: 'Name', flex: 2 },
            { field: 'EndDateTime', headerName: 'End Date', flex: 2 },
            { field: 'RegistrationFee', headerName: 'Total Registration Fee',headerAlign:'right', flex: 2, align: 'right' }, // Align right
            { field: 'TotalPrizeMoney', headerName: 'Total Prize',headerAlign:'right', flex: 1.5, align: 'right' }, // Align right
            { field: 'NumRegistered', headerName: 'Participants',headerAlign:'right', flex: 1.5, align: 'right' }, // Align right
            { field: 'PayoutType', headerName: 'Payout', flex: 1.5,headerAlign:'right', align: 'right' },
            { field: 'action',
              headerName: 'Action',
              headerAlign:'center',
               align: 'right',
              width: 150,
              renderCell: renderCompletedActions,}
          ];
        }
        else if (filter == 'Cancelled') {
          dynamicColumns = [
            { field: 'GameID', headerName: '#ID', flex: 1 },
            { field: 'Description', headerName: 'Name', flex: 2 },
            { field: 'EndDateTime', headerName: 'End Date', flex: 2 },
            { field: 'RegistrationFee', headerName: 'Total Registration Fee',headerAlign:'right', flex: 2, align: 'right' }, // Align right
            { field: 'TotalPrizeMoney', headerName: 'Total Prize',headerAlign:'right', flex: 1.5, align: 'right' }, // Align right
            { field: 'NumRegistered', headerName: 'Participants',headerAlign:'right', flex: 1.5, align: 'right' }, // Align right
            { field: 'PayoutType', headerName: 'Payout', flex: 1.5,headerAlign:'right', align: 'right' },

          ];
        }

      
        setColumns(dynamicColumns);
        console.log(columns,rows);
        
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };
useEffect(()=>{
  fetchData()
  
},[filter,pageSize,page])
  
  
  const onClick = () => {
    navigate('/create-game');
  };
  const viewGame = (game) => {
    const gameData = {
      id: game.GameID,
      ...game, 
    };
    console.log(gameData.GameID);
    
  
    navigate('/create-game', { state: { gameId: gameData.GameID ,isViewMode:true} });
  }

  const editGame = (game) => {
    const gameData = {
      id: game.GameID,
      ...game, 
    };
    console.log(gameData.GameID);
    
  
    navigate('/create-game', { state: { gameId: gameData.GameID } });
  };
  const deleteGame = async (gameId) => {
    console.log(gameId,'eventttt');
    deleteGameFromTable(gameId).then(()=>{
      fetchData()
    })

    
  };
  const renderCompletedActions = (params) => {
    return(
      <div style={{ textAlign: 'center', width: '100%' }}>
        <IconButton onClick={()=>viewGame(params.row)}>
          <RemoveRedEyeIcon />
        </IconButton>
     
    </div>
    )
  }

  const renderActions = (params) => {
    return (
      <div style={{ textAlign: 'center', width: '100%' }}>
        <img onClick={() => editGame(params.row)} src={edit} className='cursor-pointer' />
        <img onClick={() => deleteGame(params.row.GameID)} src={deleteIcon} className='cursor-pointer' />
      </div>
    );
  };
if(loading){
  return(
    <Spinner/>
  )
}

  return (
    <div className='all-games-view'>
      <div className='all-games-inner-view'>
        <Typography fontFamily={'Lato'} fontSize={28} fontWeight={700}>
          {filter} Games
        </Typography>
        <div className='search-view'>
          <FormControl variant="outlined" size="small" fullWidth style={{ marginRight: 10, float: 'none' }}>
            <InputLabel>Filter By</InputLabel>
            <Select value={filter} label="Filter By" onChange={(e) => setFilter(e.target.value)}>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Running">Running</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            fullWidth
            style={{ marginRight: 10, fontFamily: 'Lato' }}
          />
          <Button onClick={onClick} variant="contained" style={{ textTransform: 'unset', backgroundColor: '#287EAD', width: '100%' }}>
            Create Game
          </Button>
        </div>
      </div>
      <div style={{ overflowX: 'hidden' }}>
        <Box sx={{ width: '100%', flex: 1, overflowX: 'hidden' }}>
        <CustomTable
          initialRows={rows}
          headerBgColor={'rgba(40, 126, 173, 0.05)'}
          columns={columns}
          // rowCount={rows.length}
          defaultSort={"GameID"}
          subHeight={230}
          rowCount={100}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          page={page}
          pageSize={pageSize}
          onPageChange={(newPage) => {
            console.log("newPage",newPage)
            setPage(newPage); // Update the page state
            fetchData(); // Fetch the data for the new page
          }}
        />
          
        </Box>
      </div>
    </div>
  );
}