import React from 'react';
import { TextField } from '@mui/material';

export default function TextInput({ label, name, value, onChange, error, helperText, ...props }) {
  return (
    <TextField
      label={label}
      name={name}
      value={value}
      variant="outlined"
      fullWidth
      
      disabled={props.disabled}
      onChange={onChange}
      FormHelperTextProps={{padding:0}}
      error={!!error} // Pass error state to the TextField component
      helperText={helperText} // Display error message
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: error ? 'red' : 'rgba(40, 126, 173, 0.12)', // Red border on error
            color: error ? 'red' : 'rgba(81, 92, 99, 0.6)', // Text color on error
          },
          '&:hover fieldset': {
            borderColor: error ? 'red' : 'rgba(40, 126, 173, 0.12)', // Red border on hover if error
          },
          '&.Mui-focused fieldset': {
            borderWidth: 1,
            borderColor: error ? 'red' : 'rgba(40, 126, 173, 0.12)', // Red border on focus if error
          },
        },
        '& .MuiInputBase-input': {
          color: 'rgba(81, 92, 99, 1)', // Change text color here
        },
        '& .MuiInputBase-input::placeholder': {
          color: 'rgba(81, 92, 99, 0.1)', // Change placeholder color here
        },
      }}
      {...props}
    />
  );
}
