class RewardDetail {
    constructor(id, position, startPosition, endPosition, rewardExpiryDate, redeemPasscode, reward, amount, prizeMoney) {
      this.id = id;
      this.position = position;
      this.startPosition = startPosition;
      this.endPosition = endPosition;
    //   this.rewardType = rewardType;
      this.rewardExpiryDate = rewardExpiryDate;
      this.redeemPasscode = redeemPasscode;
      this.reward = reward;
      this.amount = amount;
      this.prizeMoney = prizeMoney;
    }
  }
  
  class RewardPlan {
    constructor(planId, name, description, rewardType, rewardDetails,rewardExpiryDate) {
      this.planId = planId;
      this.id = planId;
      this.name = name;
      this.description = description;
      this.rewardType = rewardType;
      this.rewardExpiryDate = rewardExpiryDate;
      this.rewardDetails = rewardDetails.map(detail => new RewardDetail(
        detail.id,
        detail.position,
        detail.startPosition,
        detail.endPosition,
        detail.rewardType,
        detail.rewardExpiryDate,
        detail.redeemPasscode,
        detail.reward,
        detail.amount,
        detail.prizeMoney
      ));
    }
  }
  export default RewardPlan