import React, { useEffect, useState } from "react";
import GameComponent from "./GameComponent";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { createGame, fetchDefaultTemplates, fetchGameDetails, fetchRewards, getGroups, updateGame } from "../services/job";
import GameModel from "../models/gameModel";
import Spinner from "./Spinner";

function CreateGame() {
  const [template, setTemplates] = useState([]);
  const [groups, setGroups] = useState([]);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate()
  console.log(location.state, "location");

  const [gameData, setGameData] = useState({
    // GameID: '',
    // StartingBankroll: 1000000.00,
    MaxPlayers: "",
    MinPlayers: "",
    StartDateTime: "",
    EndDateTime: "",
    RegistrationFee: "",
    TotalPrizeMoney:0,
    // PayoutType: '',
    Status: "",
    // EntryFee: '',
    // Duration: '',
    BannerFile: null, // Should be null to handle file uploads correctly
    Description: "",
    GameDetail: "",
    LeaderboardBanner: null, // Should be null to handle file uploads correctly
    GroupID: "",
    VideoURL: "",
    // SkipTime: '',
    IsSaveAsTemplate: false,
    // isMessageEnabled: true,
    // Message: '',
    PlanId: "",
    // FeaturedPriority: '',
    PayoutType: "",
    Latitude: '',
    Longitude: '',
    InGameTrading: 'NO'?false:true, // Default value should be set if needed
  });
  useEffect(() => {
    setLoading(true)
    getGroups().then(res=>{
      let json = JSON.parse(res)
      console.log(json.Table,'json.Table');
      setGroups(json.Table)
      console.log(JSON.parse(res),'RESSS');
      
    }).catch(er=>{
      console.log(er,'ERrorr');
      setLoading(false)
    })
    fetchDefaultTemplates()
      .then((res) => {
        console.log(res, "Response");
        setTemplates(res);
      })
      .catch((er) => {
        setLoading(false)
        console.log(er, "errorrrr");
      });
      fetchRewards().then((res)=>{
        let response = res.map(i=>i.planId)
        console.log(response,'response');
        setLoading(false)
        setPlans(res)
      }).catch(er=>{
        setLoading(false)
      })
     
    if (location.state?.gameId) {
      fetchGameDetails(location.state.gameId).then((res)=>{
        console.log(res,'Ressponse');
        setGameData(res)
      }).catch((er)=>{
        setLoading(false)
        console.log(er,'errroe in fetching details');     
      }).finally(()=>{
        setLoading(false)
      })
     
    }
  }, []);
  
  const handleFieldChange = (e, checked) => {
    const { name, value } = e.target;
    console.log(name, value, checked);


    if (name == "IsSaveAsTemplate" || name == "InGameTrading") {
      setGameData({
        ...gameData,
        [name]: checked, // Set the value as the checked state
      });
    } else{
      setGameData({
        ...gameData,
        [name]: value,
      });
    }
   
  };

  const handleFileChange = (e) => {
    const { name, checked } = e.target;

    console.log(name ,checked,'eeee');
    
      setGameData({
        ...gameData,
        [name]: e.target.files[0], // Handle file input for other names
      });
    
  };
  const onSubmit = async () => {
    console.log(gameData, "Data");
  
    // Create a new FormData instance
    const data = new FormData();
  
    // Handle the special case for 'GameName'
    if (gameData.GameName) {
      // If GameName exists, you might want to replace or add 'Description'
      data.append('Description', gameData.GameName);
      delete gameData.GameName;
    }
  
    // Append other fields to FormData
    Object.keys(gameData).forEach((key) => {
      console.log(key, gameData[key]);
      data.append(key, gameData[key]);
    });
  
    console.log(localStorage.getItem("user"), "localStorage.getItem");
    console.log(data);
  
    // Check if we're updating or creating a game
    if (location.state?.gameId) {
      try {
        setLoading(true)
        await updateGame(data);
        alert("Game updated successfully!");
        setLoading(false)
      } catch (error) {
        console.error("Error updating game:", error);
        alert("Error in updating game");
        setLoading(false)
      }
    } else {
      setLoading(true)
      createGame(data)
      .then(res => {
        console.log(res, 'Game created successfully');
        setLoading(false);
        
        // Navigate to 'allgames' after successful game creation
        navigate('/allgames');
      })
      .catch(er => {
        console.log(er, 'Error in creating game...');
        setLoading(false);
      });
    }
  };
  const handlePlayVideoClick = () => {
   
  };
  if(loading){
    return(
      <Spinner/>
    )
  }
  return (
    <GameComponent
      gameData={gameData}
      onPlayVideoClick={handlePlayVideoClick}
      mode={location.state?.isViewMode}
      gameId={location.state?.gameId}
      onFieldChange={handleFieldChange}
      onSubmit={onSubmit}
      templates={template}
      onFileChange={handleFileChange}
      groups={groups}
      plans={plans}
    />
  );
}

export default CreateGame;
