import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import AllGames from './pages/dashboard/AllGames'
import Dashboard from './pages/dashboard/Dashboard';
import { useEffect } from 'react';
import CreateGame from './components/GameDetail';
import GameDetails from './pages/dashboard/GameDetails';
import '../src/styles/global.css'
import Rewards from './pages/dashboard/Rewards'
import CreateReward from './components/CreateReward';
import Login from './pages/auth/Login'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Dashboard />}>
                <Route path="allgames" element={<AllGames />} />
                <Route path="create-game" element={<CreateGame />} />
                <Route path="game-detail" element={<GameDetails />} />
                {/* <Route path="create-reward" element={<CreateReward />} /> */}
                <Route path="rewards" element={<Rewards />} />
              </Route>
            </Route>
            {/* <Route path="*" element={<Navigate to="/login" />} /> */}
          </Routes>
    </BrowserRouter>
  );
}

const PrivateRoute = () => {
  useEffect(()=>{
    localStorage.getItem('user')
  },[])

  return localStorage.getItem('user') ? <Outlet /> : <Navigate to="/login" />;
};

