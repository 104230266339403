import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, IconButton, Switch } from '@mui/material';
import CustomTable from '../../components/CustomTable';
import LongFormDialog from '../../components/Dialog';
import deleteIcon from '../../assets/svg/delete-icon.svg';
import edit from '../../assets/svg/edit-icon.svg';
import RewardForm from '../../components/RewardForm';
import { fetchRewardDetails, fetchRewards } from '../../services/job';
import Spinner from '../../components/Spinner';

export default function Rewards() {
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [rowsData, setRowsData] = useState([]); // Updated: Store rows in state

  useEffect(() => {
   
    setLoading(true)
    fetchRewards().then(res=>{
      console.log(res,res);
      setRowsData(res)
    }).finally(()=>{
      setLoading(false)
    })
  }, []);
const fetchRewardDetail = (id) => {
  fetchRewardDetails(id).then(res=>{
    console.log(res,'EEEE');
    setSelectedReward(res)
    setOpen(true);
  })
}
  const onClick = (reward) => {
    console.log(reward,'rewardreward');
    if(reward){
      fetchRewardDetail(reward.id)
    }
    else{
      setOpen(true)
    }
    // setSelectedReward(reward);
   
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedReward(null);
  };

  // const handleDelete = (rowToDelete) => {
  //   setRowsData((prevRows) => prevRows.filter(row => row.id !== rowToDelete.id));
  // };

  if(loading){
    return(
      <Spinner/>
    )
  }

  return (
    <div className='all-games-view'>
      <div className='all-games-inner-view'>
        <Typography fontFamily={'Lato'} fontSize={28} fontWeight={700}>Rewards</Typography>
        <div className='search-view'>
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            fullWidth
            style={{ marginRight: 10, fontFamily: 'Lato' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button onClick={() => onClick(null)} variant="contained" style={{ textTransform: 'unset', backgroundColor: '#287EAD', width: '100%', }}>
            Create Reward
          </Button>
        </div>
      </div>
      <Box sx={{ width: '100%' }}>
        <CustomTable
          initialRows={rowsData}
          headerBgColor={'rgba(40, 126, 173, 0.05)'}
          columns={columns(onClick)}
          rowCount={rowsData.length}
          defaultSort={"address"}
          subHeight={230}
        />
      </Box>

      <LongFormDialog title={selectedReward ? "Edit Reward" : "Create Reward"} open={open} handleClose={handleClose}>
        <RewardForm reward={selectedReward} />
      </LongFormDialog>
    </div>
  );
}

const columns = (onClick) => [
  { field: 'id', headerName: 'Id#', flex: 0.2 }, // Adjusted flex value for consistent sizing
  { field: 'name', headerName: 'Name', flex: 0.3 },
  { field: 'name', headerName: 'Description', flex: 0.5 },
  { field: 'rewardExpiryDate', headerName: 'Expiration date', flex: 0.3 },
  { field: 'rewardType',headerAlign:'center', headerName: 'Reward Type',align:'center',flex: 0.2 }, // Adjusted flex value to replace width
  { 
    field: 'action', 
    headerName: 'Action',
    align:'center', 
    headerAlign:'center',
    // headerAlign:'center' ,
    renderCell: (params) => (
      <div className='align-items-center'>
        {/* <IconButton onClick={() => onClick(params.row)}> */}
          <img src={edit}  onClick={() => onClick(params.row)} alt="edit" className='cursor-pointer' />
        {/* </IconButton> */}
        {/* <IconButton onClick={null}> */}
          {/* <Switch defaultChecked /> */}
        {/* </IconButton> */}
      </div>
    ), 
    flex: 0.2// Adjusted flex value to replace width
  },
];