import toast from 'react-hot-toast';
import GameModel from '../models/gameModel'
import RewardPlan from '../models/rewardModel'
import Api from './axios'
import TemplateModel from '../models/templateModel'
export const authenticateUser = async (data) => {
  try {
    const response = await Api.post(`Token/Authenticate`, data, {
      headers: {
        "ngrok-skip-browser-warning": "1",
      },
    });
    console.log(response,'response.data');
    if(response.data.status==0){
      toast(response.data.message)
    }
    else{
      const token = response.data.data.token;

      // Store token in localStorage
      await localStorage.setItem('user', token);
      await localStorage.setItem('token', token);
  
      console.log('User Token:', localStorage.getItem('user'));
      console.log('Auth Token:', localStorage.getItem('token'));
     
      // Redirect user after successful authentication
      window.location.href = '/allgames';
    }
   
    return response.data
  } catch (error) {
    console.error('Error authenticating user:', error);
    // alert('Error in authenticating user')
  }
};
//-------Get all Games----------------//

export const getAllGames = async (filter, page, pageSize) => {
  try{
    const res = await Api.get(`Game/AllGames`, {
      params: {
        status: filter,
        pageIndex: page,     // Adding page index
        pageSize: pageSize,  // Adding page size
      },
      headers: {
        'Content-Type': 'application/json',
        // 'ngrok-skip-browser-warning': '1',
      },
    });
    return res.data;
  }
  catch(error){
    console.log(error,'EROR IN ');
    
  }
  
};

//--------------Create Game-----------------//

export const createGame = (data) => {
  return new Promise((resolve, reject) => {
    Api.post('Game/CreateGame', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        "Accept": "*/*",
      },
    })
      .then((response) => {
        // Show success toast message
        toast.success('Game created successfully!');
        resolve(response.data); // Resolve the promise with the response data
      })
      .catch((error) => {
        console.error('Error creating game:', error);

        // Check if the error has a response and data to process
        if (error.response && error.response.data) {
          const errorData = error.response.data;

          // Loop through each field in the error data and display the messages dynamically
          Object.keys(errorData).forEach((key) => {
            if (Array.isArray(errorData[key])) {
              errorData[key].forEach((message) => {
                toast.error(`${key} Error: ${message}`);
              });
            } else {
              toast.error(`${key} Error: ${errorData[key]}`);
            }
          });
        } else {
          // Fallback error message for unexpected issues
          toast.error('Failed to create the game. Please try again.');
        }
        reject(error); // Reject the promise with the error
      });
  });
};
//-----------------------Update Game------------------------//

  export const updateGame = async (data) => {
    try {
      const response = await Api.put("Game/Update", data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Accept": "*/*",
         
          // "ngrok-skip-browser-warning": "1",
        },
      });
      alert('Game updated successfully!');
      return response.data;
    } catch (error) {
      console.error('Error updating game:', error);
      throw error; // Re-throw error if needed for further handling
    }
  };
//-------------------Delete Game --------------------//

  export const deleteGameFromTable = async (gameId) => {
    try {
      let req={
     
          "GameID": gameId
        
      }
      const response = await Api.put(`Game/CancelGame`,req, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status !== 200) {
        throw new Error('Failed to delete the reward');
      }
      alert('Reward deleted successfully')
      console.log('Reward deleted successfully', response.data);

      return response.data;
    } catch (error) {
       alert('Error deleting the reward');
    }
  }

//-------------------Fetch Game Detailssss-----------------//

  export const fetchGameDetails = async (gameId) => {
    try {
      const response = await Api.get(`Game/GameDetails?GameID=${gameId}`, {
        headers: {
          'Content-Type': 'application/json',
         
          // 'ngrok-skip-browser-warning': '1',
        },
      });
      
      console.log(response, 'RESPONSEEEEE')
      const data = response.data.Table[0]
      console.log(response.data.Table[0],'response.data.Table[0]');
      
      const responseModel = new GameModel(data)
      return responseModel
      
    } catch (error) {
      console.error('Failed to fetch game details:', error);
      throw error
    }
  };

//-------------------Fetch Default Templates-----------------//



export const fetchDefaultTemplates = async () => {
  try {
    const response = await Api.get('Game/Default-Templates', {
      headers: {
        'Content-Type': 'application/json',
        // 'ngrok-skip-browser-warning': '1',
      },
    });

    if (response.status !== 200) {
      throw new Error('Failed to load templates');
    }

    const data = response.data; // No need to parse JSON again
    console.log('data', data);

    // Ensure `data.Table` is an array
    if (Array.isArray(data.Table)) {
      const gameInstances = data.Table.map(gameData => new TemplateModel(gameData));
      return gameInstances;
    } else {
      throw new Error('Unexpected data format');
    }
  } catch (error) {
    console.error('Error fetching default templates:', error);
  }
};


//-------------------Update Default Templates-----------------//

  export const updateDefaultGames = async() => {
    try {
      const response = await Api.get('Game/UpdateDefaultGame', {
        headers: {
          'Content-Type': 'application/json',
          // 'ngrok-skip-browser-warning': '1',
        },
      });
  
      if (response.status !== 200) {
        throw new Error('Failed to load templates');
      }
      let json = response.data

      console.log('data',json);
      console.log(typeof response.data, 'type')
     return json ; // Assuming setTemplates is defined somewhere in your component
    } catch (error) {
      console.error('Error fetching default templates:', error); // Handle any errors here
    }
  }

//-------------------Fetch Rewards ----------------------//

  export const fetchRewards = async () => {
    try {
      const response = await Api.get('Global/Rewards', {
        headers: {
          'Content-Type': 'application/json',
          // 'ngrok-skip-browser-warning': '1',
        },
      });
      console.log(response.data,'RESPONSEEEEEEEEEEE');
      
      const rewardPlans = response.data.map(plan => new RewardPlan(
        plan.planId,
        plan.name,
        plan.description,
        plan.rewardType,
        plan.rewardDetails,
        plan.rewardExpiryDate
      ));
      // console.log(rewardPlans,'rewardPlans');
      
      return rewardPlans
      // setRowsData(formattedData); // Set the fetched data
    } catch (error) {
      console.error("There was an error fetching the reward data!", error);
    }
  };
  export const fetchRewardDetails = async (id) => {
    try {
      const response = await Api.get(`Global/Plan?planId=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          // 'ngrok-skip-browser-warning': '1',
        },
      });
      console.log(response.data,'RESPONSEEEEEEEEEEE');
      
      // const rewardPlans = response.data.map(plan => new RewardPlan(
      //   plan.planId,
      //   plan.name,
      //   plan.description,
      //   plan.type,
      //   plan.rewardDetails
      // ));
      // console.log(rewardPlans,'rewardPlans');
      
      return response.data
      // setRowsData(formattedData); // Set the fetched data
    } catch (error) {
      console.error("There was an error fetching the reward data!", error);
    }
  };


  export const updateReward = async (data) => {
    try {
      const response = await Api.put(`Game/UpdateReward`,data, {
        headers: {
          'Content-Type': 'application/json',
          // 'ngrok-skip-browser-warning': '1',
        },
      });
      console.log(response.data,'RESPONSEEEEEEEEEEE');
      
      return response.data
      // setRowsData(formattedData); // Set the fetched data
    } catch (error) {
      console.error("There was an error fetching the reward data!", error);
    }
  };

//-------------------Create Reward-----------------//

  export const createReward = async (data) => {
    try {
      const response = await Api.post(`Game/Create-Reward` ,data, { headers: {
          'Content-Type': 'application/json',

        }});
      console.log(response,'RESPONSEEEEE');
      
      console.log('Reward created successfully', response.data);
      return response.data;
    } catch (error) {
     
      console.log('Error creating the reward:', error);
      alert('Error creating the reward')
    }
  }
  export const getGroups = async () => {
    try {
      const response = await Api.get('Group/GetGroups', {
        headers: {
          'Content-Type': 'application/json',
          // 'ngrok-skip-browser-warning': '1',
        },
      });
      console.log(response.data.d,'RESPONSEEEEEEEEEEE');
      // let json = JSON.parse(response.data)
      // console.log('JSONNFDFJEJFE',json);
      
      
      return response.data.d
      // setRowsData(formattedData); // Set the fetched data
    } catch (error) {
      console.error("There was an error fetching the reward data!", error);
    }
  };