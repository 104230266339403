class GameModel {
  constructor({
    GameID,
    MaxPlayers,
    MinPlayers,
    StartDateTime,
    EndDateTime,
    RegistrationFee,
    TotalPrizeMoney,
    Status,
    Duration,
    InGameTrading,
    TradingStartTime,
    TradingEndTime,
    NumRegistered,
    BannerFile,
    BannerOn,
    Description,
    GameDetail,
    LeaderboardBanner,
    GroupID,
    RewardID,
    VideoURL,
    PayoutType,
    PlanID,
    FeaturedPriority,
    Latitude,
    Longitude,
  }) {
    this.GameID = GameID;
    this.MaxPlayers = MaxPlayers;
    this.MinPlayers = MinPlayers;
    this.StartDateTime = StartDateTime;
    this.EndDateTime = EndDateTime;
    this.RegistrationFee = parseFloat(RegistrationFee).toFixed(2)||0.00;
    this.TotalPrizeMoney = parseFloat(TotalPrizeMoney).toFixed(2)||0.00;
    this.Status = Status;
    this.Duration = Duration;
    this.InGameTrading = InGameTrading;
    this.TradingStartTime = TradingStartTime;
    this.TradingEndTime = TradingEndTime;
    this.PayoutType = PayoutType;
    this.NumRegistered = NumRegistered;
    this.BannerFile = BannerFile;
    this.BannerOn = BannerOn;
    this.Description = Description;
    this.GameDetail = GameDetail;
    this.LeaderboardBanner = LeaderboardBanner;
    this.GroupID = GroupID;
    this.RewardID = RewardID;
    this.VideoURL = VideoURL;
    this.PlanID = PlanID;
    this.FeaturedPriority = FeaturedPriority;
    this.Latitude = Latitude;
    this.Longitude = Longitude;
  }
}

export default GameModel;
