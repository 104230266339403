class TemplateModel {
    constructor({
      ID,
      GameName,
      StartTime,
      EndTime,
      Amount,
      BannerFile,
      Code,
      GameDetail,
      GroupID,
      InGameTrading,
      LeaderboardBanner,
      MaxPlayers,
      MinPlayers,
      PayoutType,
      PlanId,
      RegistrationFee,
      RewardID,
      TotalPrizeMoney,
      Latitude,
      Longitude
    }) {
      console.log(ID,'jsjdjjdksk');
      
      this.ID = ID;
      this.Description = GameName;
      this.StartTime = StartTime;
      this.EndTime = EndTime;
      this.Amount = Amount;
      this.BannerFile = BannerFile;
      this.Code = Code;
      this.GameDetail = GameDetail;
      this.GroupID = GroupID;
      this.InGameTrading = InGameTrading;
      this.LeaderboardBanner = LeaderboardBanner;
      this.MaxPlayers = parseInt(MaxPlayers)||0;
      this.MinPlayers = parseInt(MinPlayers)||0;
      this.PayoutType = PayoutType;
      this.PlanId = PlanId;
      this.RegistrationFee = parseFloat(RegistrationFee).toFixed(2)||0.00
      this.RewardID = RewardID;
      this.TotalPrizeMoney = parseFloat(TotalPrizeMoney).toFixed(2)||0.00
      this.Latitude = Latitude||'';
      this.Longitude = Longitude||''
    }
  }
  export default TemplateModel
  