export default {
    gradientForm: '#A376F1',
    primary: '#7d5fff',
    white: '#FFFFFF',
    dark: '#444',
    bgColor: 'rgba(246, 252, 255, 1)',
    warning: '#f0d500',
    danger: '#FF0D0E',
    gray: '#666666',
    black: '#0a0a0a',
    green: '#0FBE00',
    orange: '#FF4E00',
    lightOrange: '#EC9F05',
    lightRed: "rgba(191, 0, 0, 0.6)",
    _30PerGrey:"rgba(81, 92, 99, 0.3)",
    _60PerWhite:'rgba(255, 255, 255, 0.6)',
    _10PerWhite:'rgba(255, 255, 255, 0.1)',

    lighterGrey:'rgba(229,229,229,1)',
    gradientBlue: "rgba(40, 126, 173,1)",
    gradientYellow:"rgba(237, 171, 0, 1)",
    gradientLightBlue: "rgba(40, 126, 173, 0.2)",
    gradientLightYellow:"rgba(237, 171, 0, 0.2)",
    _60PerGrey:'rgba(0,0,0,0.6)',

    //  App Colors

    appColor: '#06002C',
    appTextInputBackgroundColor: 'rgba(40, 126, 173, 0.05)',
    lightGrayColor: 'rgba(81, 92, 99, 0.6)',
    veryLightGrayColor: 'rgba(81, 92, 99, 0.7)',
    appTabColor: '#9B99AB',
    topBarLabel: '#287EAD',
    redCancelColor: '#BF0000',
    lightCyanColor: '#17A2B8',
    dateColor: 'rgba(6, 0, 44, 0.6)',
    dateContainer: 'rgba(40, 126, 173, 0.1)',
    lightBlueBorder: 'rgba(40, 126, 173, 0.13)',
    lightYellowColor: '#EDAB00',
    lightBlueColor: 'rgba(40, 126, 173, 0.1)',
    appGrayColor: '#858585',
    darkGrayColor: '#515C63',
    lightWhite: 'rgba(255, 255, 255, 0.8)',
    lightRedColor: 'rgba(191, 0, 0, 0.6)',
    whiteLightColor: 'rgba(0, 0, 0, 0.1)',
    iconAppColor: '#17A2B8',
    appDividerColor: '#EDEDED',
    skyBlue: '#3495CA',
    blueColor:'rgba(235,242,246,1)',
    lighterBlue: '#F9F9F9',
    shadowColor:"#171717",
    drawerLabel:'#06002C',
    drawerBottom:'rgba(155, 155, 155, 0.25)',
    iconBackgroundColor: "rgba(234,234,237,1)",

};
